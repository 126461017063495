import React from 'react'
import useStoryblokApi from 'hooks/useStoryblokApi'
import useRemark from 'hooks/useRemark'
import C from './index.js'

export default function EditableTransformer({ data, ...props }) {
  const subgroup =
    useStoryblokApi({
      by_uuids: data.subgroup,
      resolve_relations: 'accessories,properties',
    })?.stories[0] || {}
  const isAccessory =
    subgroup.content?.component === 'product-accessory-subgroup'
  const type = isAccessory ? 'accessories' : 'properties'
  const description = useRemark(subgroup.content?.description)
  const descriptions = useRemark(
    subgroup.content?.[type]?.map(x => x.content?.description)
  )
  return (
    <C
      data={{
        ...data,
        subgroup: [
          {
            ...subgroup,
            component: {
              ...subgroup.content,
              description,
              __typename: isAccessory
                ? 'StoryblokProductAccessorySubgroup'
                : 'StoryblokProductPropertySubgroup',

              [type]: subgroup.content?.[type]?.map((acc, idx) => ({
                ...acc,
                component: { ...acc.content, description: descriptions?.[idx] },
              })),
            },
          },
        ],
      }}
      {...props}
    />
  )
}
